import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor dao pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="bluecolor">Benefits Of </span>Our DAO Development Services
            </h2>
            <p className="text-center">
            Leverage and enhance your capability of business with our comprehensive DAO development services which offer you the following favourabilities.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Decentralization</h3>
                <p className="pharagraph">
                Unlike Centralized systems, DAOs are entrusted with Decentralization. The decentralization ensures a secure and transparent experience with immutable transactions, closing the door to any fraudulent activities or scams.
                </p>
              </div>
              <div className="square" >
                <h3>Zero Intervention</h3>
                <p className="pharagraph">
                The Decentralization in DAO requires no intervention from central authority or any human interactive regulators. The smart contracts integrated into DAO automate and self-regulate the system by executing codes that govern the platform.
                </p>
              </div>
              <div className="square" >
                <h3>Open Network</h3>
                <p className="pharagraph">
                DAO is an open-source network supporting people from all over the globe who have internet facilities to participate in the platform. A community of like-minded dedicated users can be summoned easily with DAO's nature and features.
                </p>
              </div>
              <div className="square" >
                <h3>Cost Effective</h3>
                <p className="pharagraph">
                Elimination of intermediaries and relying completely on automated smart contract regulations provides you with cost-effective results. This makes the platform especially attractive for crowdfunding and helps in investment management.
                </p>
              </div>
              <div className="square" >
                <h3>Security</h3>
                <p className="pharagraph">
                The DAO helps to safeguard user details and transactions by regulating the platform and enhancing its tamper-proof governance. Smart contracts facilitate rules and help eliminate human error, scams, and fraud risks.
                </p>
              </div>
              <div className="square" >
                <h3>Transparency</h3>
                <p className="pharagraph">
                The decentralized nature of DAO makes the entire system transparent, allowing anyone to see through the process and transactions completely. This paved the way to earning trust and confidence among the users of the platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
